
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotFoundPage',
  setup() {
    const router = useRouter();
    function goHome() {
      router.push({
        path: '/',
      });
    }

    return {
      goHome,
    };
  },
});
